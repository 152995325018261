

<style>
.custom-option {
  display: flex;
  align-items: center;
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Categories{{ search }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->
        <!-- 
        <button
          id="popover-button-bottom"
          href="#"
          tabindex="0"
          type="button"
          class="btn notofication-btn mr-1"
          @click="onPopovcerOpen1()"
        >
          <div>
            <img
              src="../../../assets/images/client/filter.svg"
              alt="Snow"
            />
          </div>
        </button> -->

        <!-- <b-popover
          ref="popover"
          target="popover-button-bottom"
          triggers="click"
          placement="Left"
          class="custom-popover pop-btn"
          :show.sync="popoverShow1"
        >
          <div class="center custom-vs-select" style="border: none">
            <vs-select class="" shadow placeholder="Select" v-model="value">
              <vs-option class="d-none" label="Status" value="1"
                >Status</vs-option
              >
              <vs-option label="All" value="2">All</vs-option>
              <vs-option label="Active" value="3">Active</vs-option>
              <vs-option label="Disabled" value="4">Disabled</vs-option>
              <vs-option label="Inactive" value="5">Inactive</vs-option>
            </vs-select>
          </div>
        </b-popover> -->

        <!-- <button type="button" class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button> -->

        <div v-if="popoverShow" class="popover-manual">
          <div class="popover-inner">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>
          <button @click="HandelClickMultipleDublicate()" v-b-tooltip.hover.v-primary title="Copy" type="button" class="btn notofication-btn"
            style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn ml-1" v-b-tooltip.hover.v-primary title="Active-Inactive" @click="HandelClickMultipleEnableDisable()"
            style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn" @click="HandelClickMultipleDelete()" v-b-tooltip.hover.v-danger title="Delete"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </div>
        </div>
        <button id="popover-button-variant" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1" v-b-tooltip.hover.v-primary title="Select All"
          @click="onPopovcerOpen()">
          <div>
            <img id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />
          </div>
        </button>

        <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 16px" v-b-modal.modal
          @click="getSequence()">
          +&nbsp; Add Category
        </button>

        <b-modal ref="modal"  size="lg" id="modal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:400px;padding: 10px 20px;" class="form-row mt-1">
              <div class="form-group">
                <h2>{{edit?'Edit ' :'Add '}}Category</h2>
              </div>
              <div class="form-group ml-1 d-flex align-items-center">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="clickAddCategory">
              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Category Name (English)">
                    <validation-provider #default="{ errors }" name="Category Name (English)" rules="required">
                      <b-form-input v-model="name_en" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Category Name (English)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Category Name (Arabic)">
                    <validation-provider #default="{ errors }" name="Category Name (Arabic)" rules="required">
                      <b-form-input v-model="name_ar" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Category Name (Arabic)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Image Url">
                    <validation-provider #default="{ errors }" name="Image Url" rules="required">
                      <b-form-input v-model="image_url" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Image Url" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Sequence">
                    <validation-provider #default="{ errors }" name="Sequence" rules="required|integer">
                      <b-form-input v-model="sequence" type="number" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Sequence" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <b-form-group class="mb-2" label="Brand">
                <validation-provider #default="{ errors }" name="Brand" rules="required">
                  <v-select multiple shadow :options="clientStoreData" placeholder="Select Brand" label="name_en"
                    v-model="selectedValue" :close-on-select="false" class="cate-vs-select">
                    <template v-slot:option="option">
                      <div style="display: flex;">
                        <strong class="ml-2">{{ option.name_en }}</strong>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="modal-footer1">
                <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="clickAddCategory('addmore')">
                  Add Another Category
                </button>
                <button type="button" @click="clickAddCategory" class="btn btn-primary">{{ edit ?
                  'Update' : 'Add' }}</button>
              </div>
            </b-form>
          </validation-observer>
          <!-- <div>
        <div style="display:flex;">
          <button type="button" class="btn btn-secondary">Cancel</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
      </div> -->
        </b-modal>

        <b-modal id="showmodal" ref="showmodal" size="lg" class="m-2 custom--model">
          <template #modal-header>
            <div class="d-flex">
                <div class="mr-2">
                <b-img v-if="checkURL(getImage(showItem))" v-bind="mainProps"  rounded="circle" :src="getImage(showItem)" alt="Circle image"
                  class="d-inline-block" />
                <b-img v-else v-bind="mainProps" :src="require('@/assets/images/no-pic.png')"
                  alt="Circle image" class="d-inline-block" />
                </div>
                <div>
                  <div class="font-weight-bolder h3">{{ showItem.name }} - {{showItem.name_ar}}</div> 
                          <div class="text-muted h5 text-black">{{showItem.name_ar}}</div>
                        <div class="pt-1">
                            <span v-if="showItem.is_active" class="btn-outline-success-view">Active</span>
                            <span v-else class="btn-outline-danger-view">Inactive</span>
                          </div>
                </div>
              </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <!-- <b-col md="12" class="d-flex mt-2">
              <div>
                <div class="mb-half">Title</div>
                <p class="font-weight-bolder mt-1">{{ showItem.meta_title }}</p>
              </div>
            </b-col> -->
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.sequence }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Id</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.id }}</div>
              </div>
            </b-col>
            <!-- <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Search Keyword En</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.name }}</div>
              </div>
            </b-col> -->
            <b-col v-if="showItem" md="12" class="d-flex">
              <div>
                <div class="mb-half">Brands</div>
                <div class="h5 font-weight-bolder">
                  <div class="show-categories-multiple">
                    <div v-for="(item,index) in showItem.clients" class="d-flex text-white show-categories" :key="index">
                        {{item.name_en}}
                        <feather-icon icon="XIcon" class="ml-1" size="20" />
                    </div>
                  </div>
                  </div>
              </div>
            </b-col>
            <!-- <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Image web</div>
                <b-img v-if="checkURL(showItem.image_web)" v-bind="mainProps" :src="showItem.image_web" alt="Circle image"
                  class="d-inline-block" />
                <b-img v-else v-bind="mainProps" :src="require('@/assets/images/no-pic.png')"
                  alt="Circle image" class="d-inline-block" />
              </div>
            </b-col> -->
            <!-- <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Image App</div>
                <b-img v-if="checkURL(showItem.image_app)" v-bind="mainProps" :src="showItem.image_app" alt="Circle image"
                  class="d-inline-block" />
                <b-img v-else v-bind="mainProps" :src="require('@/assets/images/no-pic.png')"
                  alt="Circle image" class="d-inline-block" />
              </div>
            </b-col> -->
            
            <!-- <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Icon</div>
                <b-img v-if="checkURL(showItem.icon)" v-bind="mainProps" :src="showItem.icon" alt="Circle image"
                  class="d-inline-block" />
                <b-img v-else v-bind="mainProps" :src="require('@/assets/images/no-pic.png')"
                  alt="Circle image" class="d-inline-block" />
              </div>
            </b-col> -->
          </b-row>
        </b-modal>
      </div>
    </div>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="categoriesData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable v-model="categoriesData" @change="draggableChange($event)" handle=".draggable-task-handle" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in categoriesData"
          @dblclick="handelShow(item)" :key="index">
          <b-row class="p-1">
            <b-col md="3" class="d-flex pl-2">
              <div class="d-flex">
                <div class="pr-1 d-flex align-items-center mb-1">
                  <b-form-checkbox v-if="popoverShow" v-model="checkBoxIds[item.id]" class="mr-0 mt-50" name="is-rtl"
                    inline @click="CheckBoxHandelClick(item.id)" />
                </div>
                
                <div class="mr-2">
                <b-img v-if="checkURL(getImage(item))" v-bind="mainProps" :src="getImage(item)" alt="Circle image"
                  class="d-inline-block" />
                <b-img v-else v-bind="mainProps" :src="require('@/assets/images/no-pic.png')"
                  alt="Circle image" class="d-inline-block" />
                </div>
                <div>
                  <div class="mb-half">Category Name</div>
                  <div class="font-weight-bolder text-black">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Application / Web Heading</div>
                <div class="font-weight-bolder text-black">
                  {{ getApplication(item.app_id) }} / {{ item.web_heading }}
                  <!-- {{item.app_id}} / {{item.web_heading}} -->
                </div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder text-black">{{ item.sequence }}</div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex">
              <div>
                <div class="mb-half">Status</div>
                <div class="font-weight-bolder text-black">
                  <span v-if="item.is_active" class="btn btn-outline-success btn-sm">Active</span>
                  <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                </div>
              </div>
            </b-col>
            <b-col md="1" class="
                d-flex
                align-items-center
                justify-content-center
                text-black
              ">
              <div :id="'popover-button-variant' + item.id"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px">
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
            </b-col>
          </b-row>
          <b-popover :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
            <!-- <button
            @click="onPopovcerOpen3(item.id)"
            class="notofication-btn"
            style="color: #408dff; margin: 5px"
            size="sm"
          >
            Cancel
          </button> -->
 
            <button type="button" class="btn notofication-btn ml-1" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-primary title="View"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn ml-1" @click="handelEdit(item.id)" v-b-modal.modal v-b-tooltip.hover.v-primary title="Edit"
              style="background: rgb(63 140 254 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square"
                viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn" @click="handelDelete(item.id)" v-b-tooltip.hover.v-danger title="delete"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
          </b-popover>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !categoriesData.length }"></div>
    </div>

    <button v-if="!isLoading && categoriesData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>
<script>
import draggable from "vuedraggable";
import json2csv from 'json2csv';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import { codeListGroupAction } from '@/views/components/list-group/code';
import { mapState } from 'vuex';
export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    draggable,
  },
  data() {
    return {
      selectedValue: [],
      paginationStart: 0,
      paginationEnd: 10,
      categoriesData: [],
      categoriesData1: [],
      clientStoreData: [],
      data: {},
      required, integer,
      pageNo: 1,
      name_en: '',
      name_ar: '',
      image_url: '',
      categoriesViewMore:{},
      sequence: 0,
      edit: false,
      paginationStart: 0,
      isLoading: true,
      paginationEnd: 10,
      paginationLength: 0,
      checkBoxIds: {},

      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      selectAllLabel: 'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      popoverShow: false,
      status: '',
      selectAll: false,
      showItem: {},
      value: "1",
    };
  },
  mounted() {
      this.$http.post(`${this.baseURL}/client-categories/brand`).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.clientStoreData = res.data.data
        this.clientStoreData1 = res.data.data
      })
  },
  watch: {
   'sidebar': {
     handler: 'checkIsValid',
     immediate: true, 
   },
  '$store.state.application': {
    handler: 'checkIsValid',
    immediate: true,
  },
  },
  created() { },
  computed: {
      ...mapState(['sidebar']),
    search() {
      // let data = []
      let search = this.$store.state.searchData
      if (this.$store.state.application) {
        this.getSearch(search);
      }
    },
  },
  methods: {

    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.some(data => data.name == 'Categories');
        if (!is_valid) {
          this.$router.push('/');
        }
      }
    },
    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },
    getImage(item){
      if(item.image) return item.image
      else if (item.image_web) return item.image_web
      else if (item.image_app) return item.image_app
      else if (item.icon) return item.icon
      else if (item.image_ar) return item.image_ar
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },

    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      this.clientStoreData.map(item => {
        let data = {
          name_en: item.name_en,
          name_ar: item.name_ar,
          category: item.categories.map((item) => { return item.name }).join(", "),
          description_en: item.description_en,
          description_ar: item.description_ar,
          is_active: item.is_active,
          sequence: item.sequence,
          website: item.website,
          image: item.image,
          search_keyword_en: item.search_keyword_en,
          search_keyword_ar: item.search_keyword_ar,
        }
        array.push(data);
      })
      const csvData = json2csv.parse(array)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ClientAndStore.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/categories/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.categoriesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.categoriesData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    handelEdit(id) {
      this.edit = true

      this.popoverShow = false
      this.$http.post(`${this.baseURL}/categories/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true 
        }).then((json) => {
          this.editItem = json.data.data
          this.name_en = this.editItem.name
          this.name_ar = this.editItem.name_ar
          this.image_url = this.editItem.icon
          this.sequence = parseInt(this.editItem.sequence)
          this.sequence1 = 0
              this.$http.post(`${this.baseURL}/categories/get-sequence`,{app_id: this.$store.state.application.id}).then((json) => {
                console.log(json.data.sequence, "data-=-=--")
                this.sequence1 = json.data.sequence - 1
              })
          this.selectedValue = this.editItem.clients
          this.status = this.editItem.is_active ? true : false,
            console.log(this.editItem)
        })
      //  this.$router.push(`/roles/editrole/${id}`)
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      this.$http.post(`${this.baseURL}/categories/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.categoriesData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.categoriesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      this.$http.post(`${this.baseURL}/categories/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.categoriesData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.categoriesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    categoriesViewMoreHandelClick(id){
    this.categoriesViewMore[id] = !this.categoriesViewMore[id];
    this.$forceUpdate();
    },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/categories/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getCategories()
          this.onPopovcerOpen3(id)
          this.checkBoxIds = {}
          this.$forceUpdate()
        })
    },

    HandelClickMultipleDelete() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
      this.isLoading = true
        this.$http.post(`${this.baseURL}/categories/multi-delete`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getCategories()
            this.checkBoxIds = {} 
            this.selectAllLabel = 'Select All'
            this.popoverShow= false
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleEnableDisable() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/categories/multi-enable-disable`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getCategories()
            this.popoverShow= false
            this.selectAllLabel = 'Select All'
            this.checkBoxIds = {}
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleDublicate() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(Number(key))
        }
      });
      if (arr.length > 0) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/categories/copy`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            if (res.data.status == 201) {
            this.$swal({
            title: "Success",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Success",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getCategories()
          this.popoverShow= false
          this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
          this.$forceUpdate()
          } else {
            let text =
              res.data.data.length > 0
                ? res.data.data
                    .map((i) => {
                      return Object.keys(i)
                        .map((item) => {
                          return `<strong>${item}</strong>:${i[item]}`;
                        })
                        .join('<br>');
                    })
                    .join('<br><br>')
                : res.data.message
                ? `${res.data.message}`
                : 'Error';
            this.$swal({
              title: "Error!!",
              html: text,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
        this.isLoading = false
        }
      })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    draggableChange(e) {
      console.log(e.moved, "e0-0=-=")
      let search = this.$store.state.searchData
      console.log(this.categoriesData1[e.moved.newIndex].sequence, this.categoriesData1[e.moved.oldIndex].sequence, "e0-0=-=")
      console.log(e.moved.newIndex, e.moved.oldIndex, "e0-0=-=")
      let data = {
        newIndex: this.categoriesData1[e.moved.newIndex].sequence,
        oldIndex: this.categoriesData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        app_id: this.$store.state.application.id
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/categories/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.categoriesData = []
          this.categoriesData1 = []
          this.categoriesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.categoriesData1 = res.data.data
        })
    },
    getSequence() {
      console.log("data-=-=--")
      this.name_en = '',
        this.name_ar = '',
        this.image_url = '',
        this.sequence1 = 0
        this.status = '',
        this.sequence = 0,
        this.selectedValue = []
      this.edit = false
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/categories/get-sequence`,{app_id: this.$store.state.application.id}).then((json) => {
        console.log(json.data.sequence, "data-=-=--")
        this.sequence = json.data.sequence
        this.sequence1 = json.data.sequence
      })
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    clickAddCategory(value) {
      let brand = []
      this.selectedValue.map(item => {
        brand.push(item.id)
      })
      let data = {
        name: this.name_en,
        name_ar: this.name_ar,
        image_app: this.image_url,
        icon: this.image_url,
        image_ar: this.image_url,
        image_web: this.image_url,
        is_active: this.status == true ? 1 : 0,
        sequence: this.sequence,
        app_id: this.$store.state.application.id,
        client_id: brand
      }
      if (this.edit) {
        data = { ...{ id: this.editItem.id }, ...data }
      }
      console.log(data)
      console.log(this.sequence, this.sequence1)
      if(this.sequence > this.sequence1){
        this.$swal({
                title: "Warning!!",
                text: `Sequence must be Lower than ${this.sequence1}`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
      }else{
      this.$refs.submitPrevent.validate().then((success) => {
        if(success){
        this.$http.post(`${this.baseURL}/categories/${this.edit ? 'update' : 'create'}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 201) {
              this.getCategories()
              this.checkBoxIds = {}
              this.$refs['modal'].hide()
              if(value == 'addmore'){
                if(!this.edit)
              this.$refs['modal'].show()
                this.getSequence()
              }
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
        }else{
          this.$swal({
                title: "Warning!!",
                text: `Please feel all Require Field`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
        }
      })
    }
    },
    CheckBoxHandelClick(id) {
      this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
      this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
    },
    selectAllClick() {
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.categoriesData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    getApplication(id) {
      if (id) {
        const data = JSON.parse(localStorage.applications).filter(item => item.id == id)
        return data[0].name_en
      } else {
        return ''
      }
    },
    async getCategories() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/categories/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.categoriesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.categoriesData1 = res.data.data
          this.isLoading = false
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.show-categories{
  background-color: #3F8CFF;
  border-radius:18px;
  font-weight:400;
  font-size: 18px;
  padding:12px 12px;
}
.show-categories-multiple{
  display: flex;
    flex-flow: wrap;
    column-gap: 9px;
    row-gap: 5px;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  // z-index: 10000;
  position: absolute;
  right: 6.5cm !important;
  background-color: white;
  border-radius: 26px;
  padding: 5px 15px;
  left: auto;
  top:96px
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}

.btn-outline-danger-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: #ea5455;
  // border: 1px solid #ea5455;
  border-radius: 4px;
}

.btn-outline-success-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  // border: 1px solid #0ac947;
  border-radius: 4px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

// .modal-dialog {
//   
// }

.modal-body {
  padding: 0px  40px !important;
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.cate-vs-select {
  max-width: none;
}
</style>
